<template>
    <div class="scraper">
    </div>
</template>

<script>
import axios from 'axios'
import cheerio from 'cheerio'
import moment from 'moment'
import firebase from 'firebase/compat/app'
import '@firebase/storage-compat'
import '@firebase/firestore-compat'
export default {
    name: 'Scraper',
    components: {
    },
    data() {
        return {
            events: []
        }
    },
    methods: {
        GetCalendars() {
            var storage = firebase.storage()
            var venuesRef = storage.ref('venues')
            var venues = []
            var urls = []
            venuesRef.listAll()
            .then((res) => {
                var venueCount = res.items.length
                res.items.forEach((itemRef) => {
                    var venueName = itemRef.name.slice(0, itemRef.name.indexOf('.'))
                    venuesRef.child(itemRef.name).getDownloadURL()
                    .then((url) => {
                        venues.push(venueName)
                        urls.push(url)
                    })
                    .catch((error) => {
                        console.log(error)
                    })
                    .finally(() => {
                        if(venueCount === venues.length) {
                            for(var i = 0; i < venues.length; i++) {
                                this.GetVenueCalendar(venues[i], urls[i])
                            }
                            this.$emit('venues-loaded', this.events)
                            this.$store.commit('updateEvents', {
                                events: this.events
                            })
                            this.$store.commit('updateReady', {
                                ready: true
                            })
                        }
                    })
                })
            }).catch((error) => {
                console.log(error)
            })
        },
        GetVenueCalendar(venue, url) {
            switch(venue) {
                case 'holeinthewall':
                axios.get(url)
                .then((response) => {
                    const html = response.data
                    const $ = cheerio.load(html)
                    const events = []
                    const venue = 'hole in the wall'
                    const venueSlug = 'holeinthewall'
                    $('li.yPI1TJ', html).each(function() {
                        const eventTitle = $(this).find('a.DjQEyU').text()
                        var headliner
                        if(eventTitle.indexOf('//') > 0) {
                            headliner = eventTitle.slice(0, eventTitle.indexOf(' //'))
                        } else {
                            headliner = eventTitle
                        }
                        const supports = eventTitle.slice(eventTitle.indexOf('//') + 3)
                        const url = $(this).find('a.DjQEyU').attr('href')
                        const eventDate = moment($(this).find('.v2vbgt').text() + ' 2022', 'ddd, MMM DD YYYY').format()
                        events.push({
                            headliner,
                            supports,
                            url,
                            eventDate,
                            venue,
                            venueSlug
                        })
                    })
                    this.events.push(...events)
                })
                .catch((error) => {
                    console.log(error)
                })
                break
                case 'swandive':
                axios.get(url)
                .then((response) => {
                    const html = response.data
                    const $ = cheerio.load(html)
                    const events = []
                    const venue = 'swan dive'
                    const venueSlug = 'swandive'
                    $('ul.user-items-list-item-container li.list-item', html).each(function() {
                        const eventTitle = $(this).find('h2.list-item-content__title').text()
                        var headliner
                        if(eventTitle.indexOf(',') > 0) {
                            headliner = eventTitle.slice(eventTitle.indexOf('- ') + 2, eventTitle.indexOf(','))
                        } else {
                            headliner = eventTitle.slice(eventTitle.indexOf('- ') + 1)
                        }
                        const supports = null
                        const url = $(this).find('.list-item-content__button-container a').attr('href')
                        const eventDate = moment(eventTitle.slice(0, eventTitle.indexOf(' ')) + ' 2022', 'MM/DD/YYYY').format()
                        events.push({
                            headliner,
                            supports,
                            url,
                            eventDate,
                            venue,
                            venueSlug
                        })
                    })
                    this.events.push(...events)
                })
                .catch((error) => {
                    console.log(error)
                })
                break
                case 'saxonpub':
                axios.get(url)
                .then((response) => {
                    const html = response.data
                    const $ = cheerio.load(html)
                    const events = []
                    const venue = 'saxon pub'
                    const venueSlug = 'saxonpub'
                    $('.ect-list-post', html).each(function() {
                        const headliner = $(this).find('.ect-list-title a').text()
                        const supports = null
                        const url = $(this).find('.ect-list-title a').attr('href')
                        const dom = $(this).find('.ect-date-area .ev-day').text()
                        const month = $(this).find('.ect-date-area .ev-mo').text()
                        const year = $(this).find('.ect-date-area .ev-yr').text()
                        const eventDate = moment(month + ' ' + dom + ', ' + year, 'MMM DD[,] YYYY').format()
                        events.push({
                            headliner,
                            supports,
                            url,
                            eventDate,
                            venue,
                            venueSlug
                        })
                    })
                    this.events.push(...events)
                })
                .catch((error) => {
                    console.log(error)
                })
                break
                case 'theconcourseproject':
                axios.get(url)
                .then((response) => {
                    const html = response.data
                    const $ = cheerio.load(html)
                    const events = []
                    const venue = 'the concourse project'
                    const venueSlug = 'theconcourseproject'
                    $('.list-view-item', html).each(function() {
                        var eventTitle = $(this).find('.headliners a').text()
                        var headliner
                        if(eventTitle.indexOf('at The Concourse Project') >= 0) {
                            headliner = eventTitle.slice(0, eventTitle.indexOf('at The Concourse Project'))
                        } else if (eventTitle.indexOf('at Concourse Project') >= 0) {
                            headliner = eventTitle.slice(0, eventTitle.indexOf('at Concourse Project'))
                        } else {
                            headliner = eventTitle
                        }
                        const supports = null
                        const url = $(this).find('.headliners a').attr('href')
                        const eventDate = moment($(this).find('.detail_event_date .name').text(), 'DDD MMMM YY').format()
                        events.push({
                            headliner,
                            supports,
                            url,
                            eventDate,
                            venue,
                            venueSlug
                        })
                    })
                    this.events.push(...events)
                })
                .catch((error) => {
                    console.log(error)
                })
                break
                case 'empire':
                axios.get(url)
                .then((response) => {
                    const html = response.data
                    const $ = cheerio.load(html)
                    const events = []
                    const venue = 'empire'
                    const venueSlug = 'empire'
                    $('.mec-event-article', html).each(function() {
                        var eventTitle = $(this).find('.mec-event-title a').text()
                        var headliner
                        if(eventTitle.indexOf('W/') > 0) {
                            headliner = eventTitle.slice(0, eventTitle.indexOf('W/'))
                        } else {
                            headliner = eventTitle
                        }
                        const supports = null
                        const url = $(this).find('.mec-event-title a').attr('href')
                        const eventDate = moment($(this).find('.mec-start-date-label').text() + ' 2022', 'DD MMMM YYYY').format()
                        events.push({
                            headliner,
                            supports,
                            url,
                            eventDate,
                            venue,
                            venueSlug
                        })
                        // if(moment(eventDate).isBefore(moment())) {
                        //     events.push({
                        //         headliner,
                        //         supports,
                        //         url,
                        //         eventDate,
                        //         venue,
                        //         venueSlug
                        //     })
                        // }
                    })
                    this.events.push(...events)
                })
                .catch((error) => {
                    console.log(error)
                })
                break
                case 'parish':
                axios.get(url)
                .then((response) => {
                    const html = response.data
                    const $ = cheerio.load(html)
                    const events = []
                    const venue = 'parish'
                    const venueSlug = 'parish'
                    $('.mec-event-article', html).each(function() {
                        var eventTitle = $(this).find('.mec-event-title a').text()
                        var headliner
                        if(eventTitle.indexOf('W/') > 0) {
                            headliner = eventTitle.slice(0, eventTitle.indexOf('W/'))
                        } else {
                            headliner = eventTitle
                        }
                        const supports = null
                        const url = $(this).find('.mec-event-title a').attr('href')
                        const eventDate = moment($(this).find('.mec-start-date-label').text() + ' 2022', 'DD MMMM YYYY').format()
                        if(moment().diff(eventDate) < 0) {
                            events.push({
                                headliner,
                                supports,
                                url,
                                eventDate,
                                venue,
                                venueSlug
                            })
                        }
                    })
                    this.events.push(...events)
                })
                .catch((error) => {
                    console.log(error)
                })
                break
                case 'cheerupcharlies':
                axios.get(url)
                .then((response) => {
                    const html = response.data
                    const $ = cheerio.load(html)
                    const events = []
                    const venue = 'cheer up charlies'
                    const venueSlug = 'cheerupcharlies'
                    $('.tribe-common-g-row', html).each(function() {
                        var eventTitle = $(this).find('h3.tribe-events-calendar-list__event-title').text()
                        var headliner
                        if(eventTitle.indexOf(',') > 0) {
                            headliner = eventTitle.slice(eventTitle.indexOf(' ') + 1, eventTitle.indexOf(','))
                        } else {
                            headliner = eventTitle.slice(eventTitle.indexOf(' ') + 1)
                        }
                        const supports = null
                        const url = $(this).find('h3.tribe-events-calendar-list__event-title > a').attr('href')
                        const eventDate = moment($(this).find('.tribe-events-calendar-list__event-datetime').attr('datetime'), 'YYYY-MM-DD').format()
                        if(moment().diff(eventDate) < 0) {
                            events.push({
                                headliner,
                                supports,
                                url,
                                eventDate,
                                venue,
                                venueSlug
                            })
                        }
                    })
                    this.events.push(...events)
                })
                .catch((error) => {
                    console.log(error)
                })
                break
                case 'continentalclub':
                axios.get(url)
                .then((response) => {
                    const html = response.data
                    const $ = cheerio.load(html)
                    const events = []
                    const venue = 'Continental Club'
                    const venueSlug = 'continentalclub'
                    $('.timely-tile-event', html).each(function() {
                        const headliner = $(this).find('.timely-title-text').text()
                        const supports = null
                        const url = $(this).attr('href')
                        const dom = $(this).find('.timely-day').text()
                        const month = $(this).find('.timely-month').text()
                        const eventDate = moment(month + ' ' + dom + ' 2022', 'LL').format()
                        if(moment().diff(eventDate) < 0) {
                            events.push({
                                headliner,
                                supports,
                                url,
                                eventDate,
                                venue,
                                venueSlug
                            })
                        }
                    })
                    this.events.push(...events)
                })
                .catch((error) => {
                    console.log(error)
                })
                break
                case 'mohawk':
                axios.get(url)
                .then((response) => {
                    const html = response.data
                    const $ = cheerio.load(html)
                    const events = []
                    const venue = 'Mohawk'
                    const venueSlug = 'mohawk'
                    $('.list-view-item', html).each(function() {
                        const headliner = $(this).find('.headliners a').text()
                        const supports = $(this).find('.supports').text()
                        const url = $(this).find('.headliners a').attr('href')
                        const dom = $(this).find('.dayofmonth').text()
                        const month = $(this).find('.month').text()
                        const eventDate = moment(month + ' ' + dom + ' 2022', 'LL').format()
                        if(moment().diff(eventDate) < 0) {
                            events.push({
                                headliner,
                                supports,
                                url,
                                eventDate,
                                venue,
                                venueSlug
                            })
                        }
                    })
                    this.events.push(...events)
                })
                .catch((error) => {
                    console.log(error)
                })
                break
                case 'emos':
                axios.get(url)
                .then((response) => {
                    const html = response.data
                    const $ = cheerio.load(html)
                    const events = []
                    const venue = 'Emo\'s'
                    const venueSlug = 'emos'
                    $('.frontgateFeedContainer', html).each(function() {
                        const headliner = $(this).find('.contentTitle').text()
                        let supportsText = $(this).find('.showOpening').text()
                        const supports = supportsText.substring(supportsText.indexOf('with') + 4)
                        const url = 'https://www.emosaustin.com' + $(this).find('.contentTitle a').attr('href')
                        //need to handle for dates in the following year
                        const eventDate = moment($(this).find('.frontgateFeedDate').text(), 'LLLL').format()
                        //Remove events in the past
                        if(moment().diff(eventDate) < 0) {
                            events.push({
                                headliner,
                                supports,
                                url,
                                eventDate,
                                venue,
                                venueSlug
                            })
                        }
                    })
                    this.events.push(...events)
                })
                .catch((error) => {
                    console.log(error)
                })
                break
                case 'stubbs':
                axios.get(url)
                .then((response) => {
                    const html = response.data
                    const $ = cheerio.load(html)
                    const events = []
                    const venue = 'Stubbs'
                    const venueSlug = 'stubbs'
                    $('.frontgateFeedContainer', html).each(function() {
                        const headliner = $(this).find('.contentTitle').text()
                        const supports = $(this).find('.showOpening').text()
                        const url = 'https://www.stubbsaustin.com' + $(this).find('.contentTitle a').attr('href')
                        //need to handle for dates in the following year
                        const eventDate = moment($(this).find('.frontgateFeedDate').text(), 'LLLL').format()
                        //Remove events in the past
                        if(moment().diff(eventDate) < 0) {
                            events.push({
                                headliner,
                                supports,
                                url,
                                eventDate,
                                venue,
                                venueSlug
                            })
                        }
                    })
                    this.events.push(...events)
                })
                .catch((error) => {
                    console.log(error)
                })
                break
                case 'antones':
                axios.get(url)
                .then((response) => {
                    const html = response.data
                    const $ = cheerio.load(html)
                    const events = []
                    const venue = 'Antone\'s'
                    const venueSlug = 'antones'
                    $('.fc-daygrid-day', html).each(function() {
                        const eventText = $(this).find('.tw-calendar-event-title').text()
                        if (eventText !== '' && eventText.indexOf('CANCELED') < 0) {
                            let headliner, supports
                            if (eventText.indexOf('w/') > 0) {
                                headliner = eventText.substring(0, eventText.indexOf('w/') - 1)
                                supports = eventText.substring(eventText.indexOf('w/') + 3)
                            } else {
                                headliner = eventText
                                supports = null
                            }
                            const url = $(this).find('a.fc-daygrid-event').attr('href')
                            //need to handle for dates in the following year
                            const eventDate = moment($(this).attr('data-date'), 'YYYY MM DD').format()
                            //Remove events in the past
                            
                            if(moment().diff(eventDate) < 0) {
                                events.push({
                                    headliner,
                                    supports,
                                    url,
                                    eventDate,
                                    venue,
                                    venueSlug
                                })
                            }
                        }
                    })
                    this.events.push(...events)
                })
                .catch((error) => {
                    console.log(error)
                })
                break
                case 'historicscootinn':
                axios.get(url)
                .then((response) => {
                    const html = response.data
                    const $ = cheerio.load(html)
                    const events = []
                    const venue = 'Historic Scoot Inn'
                    const venueSlug = 'historicscootinn'
                    $('.frontgateFeedContainer', html).each(function() {
                        const headliner = $(this).find('.contentTitle').text()
                        const supports = $(this).find('.showOpening').text()
                        const url = 'https://www.stubbsaustin.com' + $(this).find('.contentTitle a').attr('href')
                        //need to handle for dates in the following year
                        const eventDate = moment($(this).find('.frontgateFeedDate').text(), 'LLLL').format()
                        //Remove events in the past
                        if(moment().diff(eventDate) < 0) {
                            events.push({
                                headliner,
                                supports,
                                url,
                                eventDate,
                                venue,
                                venueSlug
                            })
                        }
                    })
                    this.events.push(...events)
                })
                .catch((error) => {
                    console.log(error)
                })
                break
                case 'aclliveatthemoodytheater':
                axios.get(url)
                .then((response) => {
                    const html = response.data
                    const $ = cheerio.load(html)
                    const events = []
                    const venue = 'ACL Live'
                    const venueSlug = 'aclliveatthemoodytheater'
                    $('.event-holder', html).each(function() {
                        const headliner = $(this).find('.event-info h2').text()
                        const supports = null
                        const url = $(this).find('.event-info a').attr('href')
                        //need to handle for dates in the following year
                        const dom = $(this).find('.holder .dom').text()
                        const month = $(this).find('.holder .month').text()
                        const year = 2022
                        const eventDate = moment(dom + month  + year, 'DD MM YYYY').format()
                        //Remove events in the past
                        if(moment().diff(eventDate) < 0) {
                            events.push({
                                headliner,
                                supports,
                                url,
                                eventDate,
                                venue,
                                venueSlug
                            })
                        }
                    })
                    this.events.push(...events)
                })
                .catch((error) => {
                    console.log(error)
                })
                break
            default:
                console.log('no venue provided')
            }
        }
    },
    mounted() {
        this.GetCalendars()
    }
}
</script>

<style scoped>

</style>