<template>
  <div v-if="this.$store.state.currentEvent" class="nav">
    <div class="left">
      <!-- <h1>Concertina</h1>
      <div class="location">Austin, TX</div> -->
    </div>
    <div class="right" ref="marqueeContainer" :style="{width: this.$store.state.videoWidth + 'px' }">
      <Vue3Marquee :delay="2">
        <div class="marquee-inner">
          <span class="performer">{{this.$store.state.currentEvent.headliner}}</span>
          <span class="material-icons">alternate_email</span>
          <img class="venue-img" :src="GetVenueLogo(this.$store.state.currentEvent.venueSlug)" />
        </div>
      </Vue3Marquee>
      <!-- <div v-else ref="headlinerContainer">
        {{this.$store.state.currentEvent.headliner}}
      </div> -->
    </div>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  name: 'Navi',
  data() {
    return {
      width: null,
      marqueeDelay: 2
    }
  },
  methods: {
    GetVenueLogo(venue) {
      return require('@/assets/venues/' + venue + '.png')
    },
    PrettyPrintDate(date) {
      return moment(date).format('M[.]D[.]YY')
    }
  },
  mounted() {
    this.width = window.innerWidth
  },
  updated() {
  }
}
</script>

<style scoped>
.nav {
  z-index: 9;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  margin-bottom: -10px;
  font-weight: bold;
  text-transform: uppercase;
}
h1 {
  margin: 0;
  font-size: 20px;
}
.left {
  text-align: left;
  font-size: 20px;
  margin-top: 8px;
  display: flex;
  justify-content: center;
}
.right {
  font-size: 90px;
  height: 90px;
  letter-spacing: -0.03em;
  white-space: nowrap;
  max-width: 100vw;
  overflow: hidden;
  text-align: right;
  line-height: 80px;
  text-overflow: ellipsis;
}
.marquee-inner {
  display: flex;
  align-items: center;
}
.marquee-inner img {
  height: 60px;
  margin: 0 40px 0 20px;
}
.marquee-inner .material-icons {
  margin-left: 20px;
}
.vue3-marquee {
  overflow: hidden;
}
@media screen and (min-width: 900px) {
  .left {
    display: block;
  }
}
</style>