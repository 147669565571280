import { createStore } from 'vuex'

export default createStore({
  state: {
    ready: false,
    events: null,
    currentVideo: null,
    currentEvent: null,
    videoWidth: null
  },
  mutations: {
    updateReady(state, payload) {
      state.ready = payload.ready
    },
    updateEvents (state, payload) {
      state.events = payload.events
    },
    updateCurrentVideo (state, payload) {
      state.currentVideo = payload.video
    },
    updateCurrentEvent (state, payload) {
      state.currentEvent = payload.currentEvent
    },
    updateVideoWidth (state, payload) {
      state.videoWidth = payload.width
    }
  },
  actions: {
  },
  modules: {
  }
})
