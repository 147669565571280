import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import YouTube from 'vue3-youtube'
import firebase from 'firebase/compat/app'
import '@firebase/storage-compat'
import Vue3Marquee from 'vue3-marquee'
import 'vue3-marquee/dist/style.css'

const firebaseConfig = {
    storageBucket: 'concertina-363813.appspot.com'
};
  
const firebaseApp = firebase.initializeApp(firebaseConfig)
export default firebaseApp.storage()

createApp(App).use(store).use(router).use(YouTube).use(Vue3Marquee).mount('#app')