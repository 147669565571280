<template>
    <div class="update-notification">
        <h1>Update available</h1>
        <p>Please update to the latest version to continue using seethat.show.</p>
        <br/>
        <br/>
        <div @click="$emit('update')" class="button">GET UPDATE</div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.update-notification {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.99);
    padding: 40px;
    text-align: center;
    box-sizing: border-box;
    color: white;
}
@media screen and (min-width: 900px) {
}
</style>