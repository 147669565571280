<template>
    <div class="controls">
        <div v-if="this.$store.state.currentEvent" class="controls-inner">
            <div class="left">
                <!-- <div v-if="this.$store.state.currentEvent.supports !== ''" class="supports">
                    Featuring {{this.$store.state.currentEvent.supports}}
                </div> -->
                <div v-if="desktop" class="date">
                    {{PrettyPrintDate(this.$store.state.currentEvent.eventDate)}} <span class="material-icons">alternate_email</span> {{this.$store.state.currentEvent.venue}}
                </div>
                <div v-else class="date">
                    {{PrettyPrintDate(this.$store.state.currentEvent.eventDate)}}<br/>{{this.$store.state.currentEvent.venue}}
                </div>
            </div>
            <div class="right">
                <a class="button" :href="this.$store.state.currentEvent.url" target="_blank">Get tickets</a>
                <div @click="ShuffleEvent()" class="button">Shuffle</div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
export default {
    name: 'Controls',
    data() {
        return {
            apiKey: 'AIzaSyCFDYKHeypbem5lDqAnc8YLAFqqNvkvLs8',
            desktop: true,
            pauseYouTubeAPI: false
        }
    },
    methods: {
        GetYouTubeVideo(performer) {
            if(this.pauseYouTubeAPI) {
                this.$store.commit('updateCurrentVideo', {
                    video: 'g4mHPeMGTJM'
                })
            } else {
                axios.get('https://youtube.googleapis.com/youtube/v3/search?part=snippet&maxResults=1&q=' + performer + '%20live&key=' + this.apiKey)
                .then((response) => {
                    this.$store.commit('updateCurrentVideo', {
                        video: response.data.items[0].id.videoId
                    })
                })
                .catch((error) => {
                    this.$emit('youtube-error', error)
                })
            }
        },
        ShuffleEvent() {
            let randomEvent = this.$store.state.events[Math.floor(Math.random()*this.$store.state.events.length)]
            this.$store.commit('updateCurrentEvent', {
                currentEvent: randomEvent
            })
            this.GetYouTubeVideo(this.$store.state.currentEvent.headliner)
        },
        PrettyPrintDate(date) {
            return moment(date).format('MMMM D[,] YYYY')
        }
    },
    mounted() {
        if(window.innerWidth >= 500) {
            this.desktop = true
        } else {
            this.desktop = false
        }
        setTimeout(() => {
            this.ShuffleEvent()
        }, 1000);
    }
}
</script>

<style scoped>
.controls {
    width: 100%;
    font-size: 32px;
    text-transform: uppercase;
}
.controls-inner {
    width: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    justify-content: center;
}
.headliner, .supports {
    white-space: nowrap; 
    max-width: 300px; 
    overflow: hidden;
    text-overflow: ellipsis;
}
.event {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}
.left {
    font-weight: bold;
    line-height: 36px;
    margin-bottom: 20px;
}
.right {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.right .button {
    width: fit-content;
    margin: 5px 0px;
}

@media screen and (min-width: 900px) {
    .controls-inner {
        flex-direction: row;
        justify-content: space-between;
    }
    .headliner, .supports {
        max-width: 500px; 
    }
    .right {
        align-items: center;
        flex-direction: row;
        justify-self: end;
    }
    .right .button:first-child {
        margin-right: 20px;
    }
    .left {
        justify-content: left;
        margin-bottom: 0;
    }
}
</style>