<template>
  <div class="home">
    <Scraper />
    <Navi />
    <Display 
    :error="error"
    />
    <Controls
    @youtube-error="handleError($event)"
    />
  </div>
</template>

<script>
import Scraper from '@/components/Scraper'
import Display from '@/components/Display'
import Controls from '@/components/Controls'
import Navi from '@/components/Navi'
export default {
  name: 'Home',
  components: {
    Scraper, Display, Controls, Navi
  },
  data() {
    return {
      error: null
    }
  },
  methods: {
    handleError(err) {
      console.log('error', err.response.status)
      this.error = err.response.status
    }
  }
}
</script>

<style scoped>
.home {
  grid-area: 1/1/1/1;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  flex-direction: column;
  width: fit-content;
  overflow: hidden;
  margin: 0 auto;
}
@media screen and (min-width: 900px) {
}
</style>