<template>
    <div class="display">
        <div :style="{width: this.width*scaleFactor + 'px', height: (this.width * scaleFactor) * ratio + 'px'}" class="placeholder">
            <div class="static animated-noise"></div>
            <div class="error" v-if="error === 403" :style="{width: this.width*scaleFactor - 20 + 'px', height: (this.width * scaleFactor) * ratio - 20 + 'px'}">
                Limit reached for the day.<br/>Please come back tomorrow.
            </div>
            <div>Loading...</div>
        </div>
        <YouTube
        v-if="this.$store.state.currentVideo"
        class="youtube"
        :width="this.width * scaleFactor"
        :height="(this.width * scaleFactor) * ratio"
        :src='"https://www.youtube.com/watch?v=" + this.$store.state.currentVideo' 
        @ready="onReady"
        @state-change="onStateChange"
        ref="youtube" />
    </div>
</template>

<script>
import YouTube from 'vue3-youtube'
export default {
    name: 'Display',
    props: {
        videoID: String,
        error: Number
    },
    data() {
        return {
            currentVideoID: null,
            ready: false,
            width: null,
            height: null,
            scaleFactor: null,
            desktop: true,
            ratio: null
        }
    },
    components: {
        YouTube
    },
    methods: {
        onReady() {
            this.$refs.youtube.playVideo()
        },
        onStateChange(event) {
            var state = event.target.getPlayerState()
            console.log(event.target.getPlayerState())
            if (state === 0) {
                console.log('Video ended.')
                this.$emit('state-change', state)
            }
        }
    },
    mounted() {
        this.height = window.innerHeight
        this.width = window.innerWidth
        if(window.innerWidth >= 500) {
            this.desktop = true
            this.ratio = 0.5625
            this.scaleFactor = 0.65
        } else {
            this.desktop = false
            this.ratio = 0.5625
            this.scaleFactor = 1
        }
        this.$store.commit('updateVideoWidth', {
            width: this.width*this.scaleFactor
        })
    }
}
</script>

<style scoped>
.display {
    grid-area: 1/1/1/1;
    border-bottom: none;
    overflow: hidden;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
}
.placeholder {
    background-color: black;
    grid-area: 1/1/1/1;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
    font-size: 22px;
    padding: 10px;
    box-sizing: border-box;
    overflow: hidden;
    color: white;
    text-transform: uppercase;
    letter-spacing: 0.05em;
}
.static, .error {
    grid-area: 1/1/1/1;
}
.youtube {
    grid-area: 1/1/1/1;
}
.error {
    z-index: 1;
    height: fit-content;
    position: absolute;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>