<template>
  <div class="wrapper">
    <router-view/>
    <transition name="fade">
      <UpdateNotification v-if="isRefresh" @update="update" />
    </transition>
  </div>
</template>

<script>
import UpdateNotification from '@/components/UpdateNotification'
export default {
  components: {
    UpdateNotification
  },
  data() {
    return {
      refreshing: false,
      isRefresh: false,
      registration: null,
      updateExists: false
    }
  },
  methods: {
    update(){
        this.isRefresh = false;
        if (this.registration || this.registration.waiting) {
          this.registration.waiting.postMessage({type:'SKIP_WAITING'});
        }
    },
    appUpdateUI: function (e){
      this.registration = e.detail;
      this.isRefresh = true;
      console.log('appUpdateUI triggered')
    }
  },
  mounted() {
    if(this.updateExists) {
      console.log("there is an update")
    } else {
      console.log('viewing the latest version')
    }
  },
  created() {
    // Listen for our custom event from the SW registration
    document.addEventListener('serviceWorkerUpdateEvent', this.appUpdateUI, { once: true })

    navigator.serviceWorker.addEventListener(
        'controllerchange', () => {
          if (this.refreshing) return;
          this.refreshing = true;
          window.location.reload();
        }
    )
  }
}
</script>


<style>
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@400;500;600;700&display=swap');
#app, body {
  margin: 0 auto;
  font-family: 'Oswald', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #801414;
  font-size: 14px;
  background-color: #FFF6E0;
  background: url(https://grainy-gradients.vercel.app/noise.svg), #FFF6E0;
}
a, a:visited, a:hover, a:active {
  text-decoration: none;
}
#nav {
  padding: 30px;
}
#nav a {
  font-weight: bold;
  color: #2c3e50;
}
#nav a.router-link-exact-active {
  color: #42b983;
}
.wrapper {
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  align-items: center;
  height: 100vh;
}
.overlay {
  grid-area: 1/1/1/1;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  flex-direction: column;
}
.button {
  cursor: pointer;
  background-color: transparent;
  padding: 0;
  color: #F52416;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 4px solid transparent;
  font-weight: bold;
  font-size: 20px;
  margin-top: 6px;
}
.button:hover {
  border-bottom: 4px solid #F52416;
}
.noise {
  background: url(https://grainy-gradients.vercel.app/noise.svg), #000000;
}
.noise-light {
  background: url(https://grainy-gradients.vercel.app/noise.svg), #FFF6E0;
}
.noise-purple {
  background: url(https://grainy-gradients.vercel.app/noise.svg), #223da1;;
}
.animated-noise {
  position: relative;
  top: -50%;
  left: -50%;
  right: -50%;
  bottom: -50%;
  width: 200%;
  height: 200vh;
  background: #0037ff url('http://assets.iceable.com/img/noise-transparent.png') repeat 0 0;
  background-repeat: repeat;
  animation: static-animation .2s infinite;
  opacity: .9;
  visibility: visible;
}

@keyframes static-animation {
    0% { transform: translate(0,0) }
    10% { transform: translate(-5%,-5%) }
    20% { transform: translate(-10%,5%) }
    30% { transform: translate(5%,-10%) }
    40% { transform: translate(-5%,15%) }
    50% { transform: translate(-10%,5%) }
    60% { transform: translate(15%,0) }
    70% { transform: translate(0,10%) }
    80% { transform: translate(-15%,0) }
    90% { transform: translate(10%,5%) }
    100% { transform: translate(5%,0) }
}

@media screen and (min-width: 900px) {
  .button {
    width: fit-content
  }
}
</style>
